/* Main section styling */
.phone-call-section {
    padding: 4rem 0;
    background-color: #ffffff;
    border-top: 1px solid #f0f4f8;
    border-bottom: 1px solid #f0f4f8;
    position: relative;
    overflow: hidden;
  }
  
  /* Decorative elements */
  .decorative-circle {
    position: absolute;
    border-radius: 50%;
    background-color: rgba(59, 130, 246, 0.08);
    z-index: 0;
  }
  
  .circle-1 {
    top: 5rem;
    left: 25%;
    width: 6rem;
    height: 6rem;
    animation: floating1 5s ease-in-out infinite alternate;
  }
  
  .circle-2 {
    bottom: 5rem;
    right: 25%;
    width: 4rem;
    height: 4rem;
    animation: floating2 7s ease-in-out infinite alternate;
  }
  
  /* Container */
  .container {
    max-width: 64rem;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  /* Section header */
  .section-header {
    text-align: center;
    margin-bottom: 2.5rem;
  }
  
  .section-title {
    font-size: 1.875rem;
    font-weight: 700;
    color: #1f2937;
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
  }
  
  .title-underline {
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    width: 100%;
    height: 0.25rem;
    background-color: #bfdbfe;
    border-radius: 0.25rem;
  }
  
  .section-description {
    font-size: 1.125rem;
    color: #4b5563;
    max-width: 42rem;
    margin: 0 auto;
    line-height: 1.6;
  }
  
  /* Form card */
  .form-card {
    background-color: #ffffff;
    border-radius: 1.5rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid rgba(226, 232, 240, 0.8);
  }
  
  .form-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.15);
  }
  
  /* Background patterns */
  .bg-pattern {
    position: absolute;
    border-radius: 50%;
    background-color: rgba(59, 130, 246, 0.05);
    z-index: 0;
  }
  
  .pattern-1 {
    top: 0;
    right: 0;
    width: 10rem;
    height: 10rem;
    opacity: 0.2;
  }
  
  .pattern-2 {
    bottom: 0;
    left: 0;
    width: 15rem;
    height: 15rem;
    opacity: 0.2;
  }
  
  /* Form header */
  .form-header {
    position: relative;
    padding: 2rem 1.5rem 2.5rem;
    color: white;
    text-align: center;
    overflow: hidden;
    background-size: 200% 200%;
    animation: gradientShift 10s ease infinite;
  }
  
  .form-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
    opacity: 0.3;
    z-index: 1;
  }
  
  .form-header-title {
    font-size: 1.75rem;
    font-weight: 700;
    position: relative;
    z-index: 10;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.5px;
    display: inline-block;
  }
  
  .form-header-title::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.7);
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
  }
  
  .wave-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 30px;
  }
  
  .wave-svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.05));
  }
  
  /* Form content */
  .form-content {
    padding: 2.5rem 2rem;
    position: relative;
    background: linear-gradient(to bottom, #ffffff, #f9fafb);
  }
  
  .form-separator {
    position: absolute;
    left: 50%;
    top: -0.75rem;
    transform: translateX(-50%);
    width: 5rem;
    height: 0.35rem;
    background: linear-gradient(90deg, #f3f4f6, #e5e7eb, #f3f4f6);
    border-radius: 9999px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  /* Success message */
  .success-container {
    text-align: center;
    padding: 1.5rem;
  }
  
  .success-icon-container {
    width: 5rem;
    height: 5rem;
    background-color: #d1fae5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.5rem;
  }
  
  .success-icon {
    width: 2.5rem;
    height: 2.5rem;
    color: #059669;
  }
  
  .success-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 0.75rem;
  }
  
  .success-message {
    font-size: 1.125rem;
    color: #4b5563;
    margin-bottom: 1rem;
  }
  
  .success-divider {
    width: 4rem;
    height: 0.25rem;
    background-color: #e5e7eb;
    border-radius: 9999px;
    margin: 1rem auto;
  }
  
  .success-reset-message {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  /* Form */
  .call-form {
    max-width: 36rem;
    margin: 0 auto;
  }
  
  .form-error-message {
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-color: #fee2e2;
    color: #b91c1c;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
  }
  
  .error-message-icon {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .form-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group-full {
    margin-bottom: 2rem;
  }
  
  .form-label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
    margin-bottom: 0.25rem;
  }
  
  .form-input {
    width: 100%;
    padding: 0.875rem 1.25rem;
    border-radius: 0.625rem;
    border: 1px solid #e2e8f0;
    transition: all 0.3s ease;
    background-color: #f8fafc;
    font-size: 1rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
  }
  
  .form-input:hover:not(:focus):not(:disabled) {
    border-color: #cbd5e1;
    background-color: #ffffff;
  }
  
  .form-input:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
    border-color: #3b82f6;
    background-color: #ffffff;
  }
  
  .input-error {
    border-color: #ef4444;
  }
  
  .input-error:focus {
    box-shadow: 0 0 0 3px rgba(239, 68, 68, 0.3);
  }
  
  .form-error {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    color: #ef4444;
    display: flex;
    align-items: center;
  }
  
  .error-icon {
    height: 1rem;
    width: 1rem;
    margin-right: 0.25rem;
  }
  
  /* Submit button */
  .submit-button {
    width: 100%;
    padding: 1.15rem 1.5rem;
    border-radius: 0.75rem;
    color: white;
    font-weight: 500;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    border: none;
  }
  
  .submit-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: all 0.6s ease;
  }
  
  .submit-button:not(:disabled):hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.15), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transform: translateY(-2px);
  }
  
  .submit-button:not(:disabled):hover::before {
    left: 100%;
  }
  
  .submit-button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  
  .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
  }
  
  .loading-spinner {
    animation: spin 1s linear infinite;
    height: 1.25rem;
    width: 1.25rem;
    color: white;
  }
  
  .spinner-track {
    opacity: 0.25;
  }
  
  .spinner-path {
    opacity: 0.75;
  }
  
  .button-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .button-icon {
    height: 1.25rem;
    width: 1.25rem;
    color: white;
  }
  
  .button-text {
    font-size: 1.125rem;
    font-weight: 600;
  }
  
  .form-disclaimer {
    margin-top: 1rem;
    font-size: 0.75rem;
    color: #6b7280;
    text-align: center;
  }
  
  /* Animations */
  @keyframes floating1 {
    0% { transform: translate(0, 0); }
    50% { transform: translate(0, -20px); }
    100% { transform: translate(0, 0); }
  }
  
  @keyframes floating2 {
    0% { transform: translate(0, 0); }
    50% { transform: translate(0, 20px); }
    100% { transform: translate(0, 0); }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }