/* ValueProps Section Styles */

/* Hide scrollbar for clean UI */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* 3D perspective for card stack */
.perspective-1000 {
    perspective: 1200px;
}

/* Background overlay for inactive cards */
.card-background::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    opacity: 0.97;
    z-index: 5;
}

/* Floating animation for decorative elements */
@keyframes float {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-15px) rotate(1deg);
    }
    100% {
        transform: translateY(0px) rotate(0deg);
    }
}

/* Fade-in animation for content elements */
@keyframes fadeIn {
    from { 
        opacity: 0; 
        transform: translateY(20px); 
    }
    to { 
        opacity: 1; 
        transform: translateY(0); 
    }
}

.animate-fadeIn {
    animation: fadeIn 0.8s ease-out forwards;
}

/* Pulse animation for indicator */
@keyframes pulse {
    0% { transform: scale(1); opacity: 0.6; }
    50% { transform: scale(1.1); opacity: 0.4; }
    100% { transform: scale(1); opacity: 0.6; }
}

/* Decorative floating animations */
@keyframes floating1 {
    0% { transform: translate(0, 0); }
    50% { transform: translate(0, -20px); }
    100% { transform: translate(0, 0); }
}

@keyframes floating2 {
    0% { transform: translate(0, 0); }
    50% { transform: translate(0, 20px); }
    100% { transform: translate(0, 0); }
}

/* Button Hover Animation */
@keyframes buttonPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(90, 207, 201, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(90, 207, 201, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(90, 207, 201, 0);
    }
}

.animate-button-pulse {
    animation: buttonPulse 1.5s infinite;
}

.horizontal-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
}

@media (max-width: 640px) {
    .horizontal-button-container {
        flex-direction: column;
        gap: 8px;
    }
}

/* Button styling classes */
.web-widget-button,
.vapi-web-button,
.vapi-call-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0 16px;
    height: 48px;
    border-radius: 24px;
    font-weight: 500;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

/* Hover effect for all buttons */
.web-widget-button:hover,
.vapi-web-button:hover,
.vapi-call-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

/* Active effect for all buttons */
.web-widget-button:active,
.vapi-web-button:active,
.vapi-call-button:active {
    transform: translateY(0);
}

/* Icon styling */
.button-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    transition: transform 0.3s ease;
}

/* Text styling */
.button-text {
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Text hover animation */
.web-widget-button:hover .button-text,
.vapi-web-button:hover .button-text,
.vapi-call-button:hover .button-text {
    transform: translateX(2px);
}

/* Icon hover animation */
.web-widget-button:hover .button-icon,
.vapi-web-button:hover .button-icon,
.vapi-call-button:hover .button-icon {
    transform: rotate(10deg) scale(1.1);
}

/* Loading spinner animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-spinner {
    animation: spin 1s linear infinite;
}