@keyframes floating1 {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-20px);
    }
  }
  
  @keyframes floating2 {
    0% {
      transform: translateY(0px) translateX(0px);
    }
    100% {
      transform: translateY(-15px) translateX(15px);
    }
  }
  
  @keyframes floating3 {
    0% {
      transform: translateY(0px) translateX(0px) rotate(0deg);
    }
    100% {
      transform: translateY(-10px) translateX(-10px) rotate(10deg);
    }
  }
  
  @keyframes pulsing {
    0%, 100% {
      opacity: 0.8;
      transform: scale(1);
    }
    50% {
      opacity: 0.5;
      transform: scale(1.05);
    }
  }


  .floating-element {
    will-change: transform;
    backface-visibility: hidden;
    perspective: 1000px;
  }


  /* Add these animations to your existing animation.css file */

@keyframes floating1 {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes floating2 {
    0% {
      transform: translateY(0px) translateX(0px);
    }
    50% {
      transform: translateY(-15px) translateX(5px);
    }
    100% {
      transform: translateY(-5px) translateX(-5px);
    }
  }
  
  @keyframes floating3 {
    0% {
      transform: translateY(0px) translateX(0px) rotate(0deg);
    }
    33% {
      transform: translateY(-8px) translateX(5px) rotate(2deg);
    }
    66% {
      transform: translateY(-4px) translateX(-5px) rotate(-2deg);
    }
    100% {
      transform: translateY(-12px) translateX(0px) rotate(0deg);
    }
  }
  
  @keyframes pulsing {
    0%, 100% {
      opacity: 0.7;
      transform: scale(1);
    }
    50% {
      opacity: 0.5;
      transform: scale(1.05);
    }
  }
  
  /* Card hover effects */
  .card-hover-effect {
    transition: all 0.3s ease;
  }
  
  .card-hover-effect:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }


  /* Add these to your animation.css file */

/* Scale transforms */
.scale-98 {
    transform: scale(0.98);
  }
  
  /* Smooth transitions for expanding/collapsing */
  .max-h-20 {
    max-height: 5rem;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
  
  .max-h-24 {
    max-height: 6rem;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
  
  .max-h-\[1000px\] {
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
  }
  
  /* Fixed position styling for the navigation indicator */
  .nav-indicator {
    transition: left 0.3s ease, width 0.3s ease;
  }
  
  /* Ensure snap scrolling works well */
  .snap-mandatory {
    scroll-snap-type: x mandatory;
  }
  
  .snap-start {
    scroll-snap-align: start;
  }
  
  /* Ensure collapsed cards don't overflow */
  .overflow-hidden {
    overflow: hidden;
  }

 